export const getSettings = (gettext, currentLanguage) => {
    let set = {
        height: 500,
        language: currentLanguage,
        invalid_elements: 'iframe',
        plugins: 'code preview image link lists paste codemirror fullpage',
        toolbar1: 'undo redo | paste | styleselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | code | preview',
        toolbar2: `firstNameTemplate secondNameTemplate ${currentLanguage === 'ru' && 'thirdNameTemplate'} | dateTemplate | image link | tags`,
        theme_advanced_buttons3_add : 'fullpage',
        fullpage_fontsizes : '13px,14px,15px,18pt,xx-large',
        fullpage_default_xml_pi : false,
        fullpage_default_cu : 'en',
        fullpage_default_title : 'My document title',
        menubar: false,
        visual: false,
        relative_urls: true,
        remove_script_host: false,
        paste_data_images: true,
        theme: 'silver',
        content_style: '.mce-content-body {font-family:Verdana,Arial,Helvetica,sans-serif;font-size:11px;margin-bottom:20px}',
        images_dataimg_filter: function (img) {
            return img.hasAttribute('internal-blob');
        },
        selector: 'textarea',
        ui_container: '.modal-dialog',
        content_css: false,
        skin: false,
        codemirror: {
            indentOnInit: true, // Whether or not to indent code on init.
            fullscreen: false,   // Default setting is false
            path: 'CodeMirror', // Path to CodeMirror distribution
            config: {           // CodeMirror config object
                lineNumbers: true
            },
            width: 800,         // Default value is 800
            height: 600,        // Default value is 550
            saveCursorPosition: true    // Insert caret marker
        },
        force_br_newlines : false,
        force_p_newlines : false,
        forced_root_block : '',
        setup: function (editor) {
            editor.ui.registry.addButton('dateTemplate', {
                icon: 'insert-time',
                tooltip: gettext.getString('Дата'),
                onAction: function () {
                    editor.insertContent('{dmy}');
                }
            });
            editor.ui.registry.addButton('firstNameTemplate', {
                text: gettext.getString('Фамилия'),
                tooltip: gettext.getString('Фамилия'),
                onAction: function () {
                    editor.insertContent('{last_name}');
                }
            });
            editor.ui.registry.addButton('secondNameTemplate', {
                text: gettext.getString('Имя'),
                tooltip: gettext.getString('Имя'),
                onAction: function () {
                    editor.insertContent('{name}');
                }
            });
            if (currentLanguage === 'ru') {
                editor.ui.registry.addButton('thirdNameTemplate', {
                    text: gettext.getString('Отчество'),
                    tooltip: gettext.getString('Отчество'),
                    onAction: function () {
                        editor.insertContent('{middle_name}');
                    }
                });
            }
            editor.ui.registry.addMenuButton('tags', {
                type: 'menubutton',
                text: gettext.getString('Переменные'),
                fetch: (callback) => {
                    let tagList = [
                        {
                            type: 'nestedmenuitem',
                            text: gettext.getString('Общие'),
                            getSubmenuItems: () => {
                                return [
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Дата') + ' d.m.Y',
                                        onAction: function () {
                                            editor.insertContent('{dmy}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: (gettext.getString('Дата d.m.Y [+/-]N дней')),
                                        onAction: function () {
                                            editor.insertContent('{dmy+N}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Дата с форматированием'),
                                        onAction: function () {
                                            editor.insertContent('{date}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Дата с форматированием [+/-]N дней'),
                                        onAction: function () {
                                            editor.insertContent('{date+N}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('День'),
                                        onAction: function () {
                                            editor.insertContent('{day}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Месяц'),
                                        onAction: function () {
                                            editor.insertContent('{month}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Год'),
                                        onAction: function () {
                                            editor.insertContent('{year}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Случайное число из N цифр'),
                                        onAction: function () {
                                            editor.insertContent('{rand:N}');
                                        }
                                    }
                                ];
                            }
                        },
                        {
                            type: 'nestedmenuitem',
                            text: gettext.getString('Администратор'),
                            getSubmenuItems: () => {
                                return [
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Фамилия'),
                                        onAction: function () {
                                            editor.insertContent('{admin_last_name}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Имя'),
                                        onAction: function () {
                                            editor.insertContent('{admin_name}');
                                        }
                                    },
                                    {
                                        type: currentLanguage === 'ru' && 'menuitem',
                                        text: gettext.getString('Отчество'),
                                        onAction: currentLanguage === 'ru' && function () {
                                            editor.insertContent('{admin_middle_name}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Электронная почта'),
                                        onAction: function () {
                                            editor.insertContent('{admin_email}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Номер телефона'),
                                        onAction: function () {
                                            editor.insertContent('{admin_phone}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Компания'),
                                        onAction: function () {
                                            editor.insertContent('{admin_company}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Отдел'),
                                        onAction: function () {
                                            editor.insertContent('{admin_department}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Должность'),
                                        onAction: function () {
                                            editor.insertContent('{admin_position}');
                                        }
                                    }
                                ];
                            }
                        },
                        {
                            type: 'nestedmenuitem',
                            text: gettext.getString('Сотрудник'),
                            getSubmenuItems: () => {
                                return [
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Фамилия'),
                                        onAction: function () {
                                            editor.insertContent('{last_name}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Имя'),
                                        onAction: function () {
                                            editor.insertContent('{name}');
                                        }
                                    },
                                    {
                                        type: currentLanguage === 'ru' && 'menuitem',
                                        text: gettext.getString('Отчество'),
                                        onAction: currentLanguage === 'ru' && function () {
                                            editor.insertContent('{middle_name}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Электронная почта'),
                                        onAction: function () {
                                            editor.insertContent('{email}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Отдел'),
                                        onAction: function () {
                                            editor.insertContent('{department}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Должность'),
                                        onAction: function () {
                                            editor.insertContent('{position}');
                                        }
                                    }
                                ];
                            }
                        },
                        {
                            type: 'nestedmenuitem',
                            text: gettext.getString('Обучение'),
                            getSubmenuItems: () => {
                                return [
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Домен'),
                                        onAction: function () {
                                            editor.insertContent('{domain}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Url системы обучения'),
                                        onAction: function () {
                                            editor.insertContent('{education_url}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Логин'),
                                        onAction: function () {
                                            editor.insertContent('{education_login}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Пароль'),
                                        onAction: function () {
                                            editor.insertContent('{education_password}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Курсы'),
                                        onAction: function () {
                                            editor.insertContent('{education_courses:\'' + (gettext.getString('пройдите до')) + '\'}');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: gettext.getString('Cертификат о прохождении курса'),
                                        onAction: function () {
                                            editor.insertContent('{education_certificate}');
                                        }
                                    }
                                ];
                            }
                        }
                    ];
                    let tagsByGroup = {};
                    let otherTags = [];
                    if (window.mceTags && window.mceTags.length) {
                        tagList.push({
                            text: '|'
                        });
                        window.mceTags.forEach( (tag) => {
                            if (tag.group) {
                                if (!tagsByGroup[tag.group]) {
                                    tagsByGroup[tag.group] = [];
                                }
                                tagsByGroup[tag.group].push({
                                    type: 'menuitem',
                                    text: tag.name,
                                    onAction: function () {
                                        editor.insertContent(tag.tag);
                                    }
                                });
                            } else {
                                otherTags.push({
                                    type: 'menuitem',
                                    text: tag.name,
                                    onAction: function () {
                                        editor.insertContent(tag.tag);
                                    }
                                });
                            }
                        });
                    }
                    for (let key in tagsByGroup) {
                        tagList.push({
                            type: 'nestedmenuitem',
                            text: key,
                            getSubmenuItems: () => {
                                return tagsByGroup[key];
                            }
                        });
                    }
                    otherTags.forEach( (tag) => {
                        tagList.push(tag);
                    });
                    callback(tagList);
                }
            });
        }
    };
    return set;
};
