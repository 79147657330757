import { Controller } from './controller';
import { MailServerModal } from '../components/mailServerModal';
import { MailServerDetailsModal } from '../components/mailServerDetailsModal';
import { BossKadrovikProfileModal } from '../components/bossKadrovikModal';
import {
    BOSS_KADROVIK_SERVICE,
    DEFAULT_PER_PAGE_SIZE,
    MAIL_SERVERS_SERVICE,
    SETTINGS_SERVICE,
    LANGUAGES,
} from '../const';
import angular from 'angular';
import { hasAccess, actionTypes, reportTypes, triggerTypes, getDomains, isSettingsLoad } from '../reselect';
import { clearSelectedData } from '../toolkit/actions';
import { fetchCerts, fetchSSO } from '../toolkit/think';
import reactSettings from 'rootReact/containers/Settings';
import FetchApi from 'rootReact/REST';

const Actions = {
    clearSelectedData,
    fetchCerts,
    fetchSSO,
};

class SettingsController extends Controller {

    constructor(
        $injector, $scope, $localStorage,
        MailServersService, SettingsService, $ngRedux,
        $location, BossKadrovikProfilesService, gettextCatalog) {
        super();
        this.config = window.config;
        this.$injector = $injector;
        this.$location = $location;
        this.$scope = $scope;
        this.$localStorage = $localStorage;
        this.reactSettings = reactSettings;
        this.gettextCatalog = gettextCatalog;
        this.MailServersService = MailServersService;
        this.SettingsService = SettingsService;
        this.BossKadrovikProfilesService = BossKadrovikProfilesService;
        this.ldapProfiles = [];
        this.bossKadrovikEnabled = $localStorage.userInfo.targetImport && $localStorage.userInfo.targetImport.bossKadrovikEnabled;
        if (this.bossKadrovikEnabled) {
            this.bossKadrovikProfiles = [];
        }
        this.courses = [];
        this.schedulerTasks = [];
        this.users = [];
        this.roles = [];
        /** TODO: Убрать методы перезагрузки списков **/
        this.loading = {};
        if (this.bossKadrovikEnabled) {
            this.bossKadrovikProfileList = BossKadrovikProfilesService.all;
            this.loading.bossKadrovik = false;
        }
        this.inProcess = {
            profile: false,
        };
        this.errors = {};
        this.form = {};
        this.modal = {};
        this.cacheCourseById = [];
        this.cacheTemplateById = [];
        this.cacheSchedulerTriggerTypeById = {};
        this.cacheSchedulerActionTypeById = {};
        this.mailServersError = false;
        this.userInfo = {
            timeZone: 'UTC',
            timeZoneGmt: '+0',
        };
        this.loading.userInfo = false;
        this.lastTimeZone = null;
        this.reactLoad = false;
        this.lastTimeZoneGmt = '+0';
        this.showPasswordForm = false;
        this.enabledSavePolicy = true;
        this.languages = [];
        angular.forEach(LANGUAGES, (langName, langCode) => {
            this.languages.push({
                code: langCode,
                label: langName,
            });
        });
        this.systemUpdateEnabled = false;
        window.settingsCtrl = this;
        this.strings = {
            eduLogLink: this.gettextCatalog.getString('журнал обучения'),
            actionLogLink: this.gettextCatalog.getString('журнал действий'),
            courseNotFound: this.gettextCatalog.getString('Курс не найден или больше не доступен'),
            unknownAction: this.gettextCatalog.getString('Неизвестное действие'),
            templateNotFound: this.gettextCatalog.getString('Шаблон не найден или больше не доступен'),
            templateRandom: this.gettextCatalog.getString('Случайный шаблон'),
            ldapLogLink: this.gettextCatalog.getString('журнал синхронизаций')
        };
        this.ngRedux = $ngRedux;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, Actions)(this);
    }

    mapStateToThis = (state) => {
        return {
            viewAccess: hasAccess(state, { sectionId: 5, rightName: 'view' }),
            downloadAllCertsAccess: hasAccess(state, { sectionId: 6, rightName: 'user_download_all_certs' }),
            educationEditable: hasAccess(state, { sectionId: 6, rightName: 'education_save' }),
            certDownload: hasAccess(state, { sectionId: 6, rightName: 'user_download_all_certs' }),
            reportEducation: hasAccess(state, { sectionId: 8, rightName: 'report_education' }),
            ldapAccess: hasAccess(state, { sectionId: 10, rightName: 'view' }),
            bossKadrovikViewAccess: hasAccess(state, { sectionId: 13, rightName: 'view' }),
            bossKadrovikSaveAccess: hasAccess(state, { sectionId: 13, rightName: 'boss_kadrovik_save' }),
            bossKadrovikDeleteAccess: hasAccess(state, { sectionId: 13, rightName: 'boss_kadrovik_delete' }),
            mailViewAccess: hasAccess(state, { sectionId: 12, rightName: 'view' }),
            mailServerUseAccess: hasAccess(state, { sectionId: 12, rightName: 'mail_server_use' }),
            settingsNewDateAccess: hasAccess(state, { sectionId: 5, rightName: 'settings_new_days' }),
            mailEnabled: state.auth.auth.license.features.mailServer,
            isSettingsLoad: isSettingsLoad(state),
            anonymization: state.auth.auth.license.features.anonymization,
            manageSSO: state.auth.auth.license.features.manageSso,
            manageLdap: state.auth.auth.license.features.manageLdap,
            licenseIsValid: state.auth.auth.license.isValid,
            days_as_new: state.auth.auth.daysAsNew,
            hashPath: state.linking.link,
            authType: state.auth.auth.authType,
            certsMessage: state.notification.certs,
            certReduce: state.certificate,
            courses: state.courses.courses,
            learningConfig: state.settings.settings.learning,
            settingsLoad: state.settings.load,
            settingsError: state.settings.error,
            mailServers: state.settings.settings.mailServers,
            timeZones: state.settings.settings.timeZones,
            schedulerTasks: state.settings.settings.schedulerTasks,
            schedulerReportTypes: reportTypes(state),
            schedulerActionTypes: actionTypes(state),
            schedulerTriggerTypes: triggerTypes(state),
            domains: getDomains(state),
        };
    };

    $onInit() {
        const { $localStorage } = this;
        if (!this.viewAccess) {
            this.$location.path($localStorage.userInfo.defaultPage || 'targets');
            this.$location.hash('');
            return;
        }
        this.loadMailServers(true);
        this.clearSelectedData();
    }

    setWatcher = (hashPath) => {
        let setWatcher = (value) => {
            if (!value || value.search(/attacksStart/g) !== -1 || value.search(/sendTraining/g) !== -1) {
                return;
            }
            if (this.hashValues?.includes(value)) {
                return;
            }
            if (value === 'scheduler' ||
                value === 'schedulerField' ||
                value === 'roleSaveField' ||
                value === 'roleField' ||
                value === 'accountCreate' ||
                value === 'accountField' ||
                value === 'ldapSaveField' ||
                value === 'ldapField'
            ) {
                return;
            }
            if (value === 'accountCreate') {
                this.focusedOn(value, this.saveUser);
                return;
            }
            if (value === 'passwordField') {
                this.focusedOn('passwordField', this.openPolicy);
            }
            if (value === 'roleSaveField') {
                this.focusedOn('roleSaveField', this.saveRole);
            }
            this.focusedOn(value);
        };
        setWatcher(hashPath);
        this.$scope.$watch(scope => {
            return scope.settingsCtrl.hashPath;
        }, (newValue, oldValue) => {
            if (newValue === oldValue || newValue === '') {
                return;
            }
            setWatcher(newValue);
        });
    };

    setForm = (type, form) => {
        this.form[type] = form;
    };

    updateDaysAsNew = () => {
        this.errors.days_as_new = null;
        this.form.daysAsNew.$setSubmitted();
        if (this.form.daysAsNew.$valid) {
            let data = {
                'days_as_new': this.days_as_new,
            };
            this.inProcess.days_as_new = true;
            this.SettingsService.changeDaysAsNew(data, this, (ctrl) => {
                ctrl.inProcess.days_as_new = null;
                ctrl.days_as_new_changed = true;
                ctrl.$localStorage.userInfo.daysAsNew = ctrl.days_as_new;
            }, (ctrl, response) => {
                if (response && response.data && response.data.error) {
                    if (response.data.field == 'days_as_new') {
                        ctrl.errors.days_as_new_changed = response.data.error;
                    }
                }
                ctrl.inProcess.days_as_new = null;
            });
        }
    };

    loadBossKadrovikProfiles = () => {
        if (this.ldapAccess) {
            this.loading.bossKadrovik = true;
            this.bossKadrovikProfileList.$page = 1;
            this.bossKadrovikProfileList.$load(DEFAULT_PER_PAGE_SIZE).then((data) => {
                this.bossKadrovikProfileList = data;
                this.bossKadrovikProfiles = this.bossKadrovikProfileList.$data;
                this.loading.bossKadrovik = false;
            });
        }
    };

    reLoadBossKadrovikProfiles = () => {
        this.loading.bossKadrovik = true;
        this.bossKadrovikProfileList.$page = 1;
        this.bossKadrovikProfileList.$load(this.bossKadrovikProfileList.$data.length).then((data) => {
            this.bossKadrovikProfileList = data;
            this.bossKadrovikProfiles = this.bossKadrovikProfileList.$data;
            this.loading.bossKadrovik = false;
        });
    };

    loadMoreBossKadrovikProfiles = () => {
        this.loading.bossKadrovik = true;
        if (this.bossKadrovikProfileList.$existNext()) {
            const prevData = this.bossKadrovikProfileList.$data;
            this.bossKadrovikProfileList.$nextPage().then((data) => {
                this.bossKadrovikProfileList.$meta = data.$meta;
                this.bossKadrovikProfileList.$data = [...prevData, ...data.$data];
                this.bossKadrovikProfiles = this.bossKadrovikProfileList.$data;
                this.loading.bossKadrovik = false;
            });
        }
    };

    saveBossKadrovikProfile = (profile) => {
        if (!this.bossKadrovikSaveAccess || !this.licenseIsValid) {
            return;
        }
        this.modal.ldap = this.$injector.instantiate(BossKadrovikProfileModal);
        this.modal.ldap.open(profile && profile.id ? profile.id : 0).then((profileData) => {
            if (profileData) {
                if (profile && profile.id) {
                    this.reLoadBossKadrovikProfiles();
                } else {
                    this.loadBossKadrovikProfiles();
                }
            }
        }, () => {
        });
    };

    deleteBossKadrovikProfile = (profile) => {
        if (!this.bossKadrovikDeleteAccess || !this.licenseIsValid) {
            return;
        }
        if (confirm(this.gettextCatalog.getString('Удалить БОСС-Кадровик профиль?'))) {
            this.BossKadrovikProfilesService.one(profile.id).then((profile) => {
                profile.$delete().then(() => {
                    this.reLoadBossKadrovikProfiles();
                });
            });
        }
        return false;
    };

    courseName = (courseId, defaultText, useDefaultText) => {
        let course = this.cacheCourseById[courseId];
        if (typeof (course) == 'undefined') {
            course = this.courses.find((item) => {
                if (item.id == courseId) {
                    return true;
                }
            });
            this.cacheCourseById[courseId] = course;
        }
        if (course && course.id > 0) {
            return course.name;
        } else {
            return +courseId > 0 && !+useDefaultText
                ? this.gettextCatalog.getString('Курс не найден или больше не доступен')
                : defaultText;
        }
    };

    templateName = (templateId, defaultText) => {
        let template = this.cacheTemplateById[templateId];
        if (typeof (template) == 'undefined' && this.templates) {
            template = this.templates.find((item) => {
                if (item.id == templateId) {
                    return true;
                }
            });
            this.cacheTemplateById[templateId] = template;
        }
        if (template && template.id > 0) {
            return template.name;
        } else {
            return +templateId > 0
                ? this.gettextCatalog.getString('Шаблон не найден или больше не доступен')
                : defaultText;
        }
    };

    schedulerTriggerTypeDescription = (triggerTypeId) => {
        let triggerType = this.cacheSchedulerTriggerTypeById[triggerTypeId];
        if (typeof (triggerType) == 'undefined' && this.schedulerTriggerTypes) {
            triggerType = this.schedulerTriggerTypes.find((item) => {
                if (item.id == triggerTypeId) {
                    return true;
                }
            });
            this.cacheSchedulerTriggerTypeById[triggerTypeId] = triggerType;
        }
        if (triggerType && triggerType.id) {
            return triggerType.description;
        }
    };

    schedulerTriggerTypeName = (triggerTypeId, defaultText, withoutDots) => {
        let triggerType = this.cacheSchedulerTriggerTypeById[triggerTypeId];
        if (typeof (triggerType) == 'undefined' && this.schedulerTriggerTypes) {
            triggerType = this.schedulerTriggerTypes.find((item) => {
                if (item.id == triggerTypeId) {
                    return true;
                }
            });
            this.cacheSchedulerTriggerTypeById[triggerTypeId] = triggerType;
        }
        if (triggerType && triggerType.id) {
            let triggerName = triggerType.name;
            if (withoutDots) {
                triggerName = triggerType.name.replace(' ...', '');
            }
            return triggerName;
        } else {
            return +triggerTypeId > 0
                ? this.gettextCatalog.getString('Неизвестное событие')
                : defaultText;
        }
    };

    schedulerReportTypeName = (reportTypeId, defaultText) => {
        let reportType = this.cacheSchedulerReportTypeById[reportTypeId];
        if (typeof (reportType) == 'undefined' && this.schedulerReportTypes) {
            reportType = this.schedulerReportTypes.find((item) => {
                if (item.id == reportTypeId) {
                    return true;
                }
            });
            this.cacheSchedulerReportTypeById[reportTypeId] = reportType;
        }
        if (reportType && reportType.id) {
            return reportType.name;
        } else {
            return +reportTypeId > 0
                ? this.gettextCatalog.getString('Неизвестный отчет')
                : defaultText;
        }
    };

    schedulerActionTypeName = (actionTypeId, defaultText, withoutDots) => {
        let actionType = this.cacheSchedulerActionTypeById[actionTypeId];
        if (typeof (actionType) == 'undefined' && this.schedulerActionTypes) {
            actionType = this.schedulerActionTypes.find((item) => {
                if (item.id == actionTypeId) {
                    return true;
                }
            });
            this.cacheSchedulerActionTypeById[actionTypeId] = actionType;
        }
        if (actionType && actionType.id) {
            let actionName = actionType.name;
            if (withoutDots) {
                actionName = actionType.name.replace(' ...', '');
            }
            return actionName;
        } else {
            return +actionTypeId > 0
                ? this.gettextCatalog.getString('Неизвестное событие')
                : defaultText;
        }
    };

    openMailServer = (serverId) => {
        this.$injector.instantiate(MailServerModal).open(serverId).then((server) => {
            if (server && server.id) {
                this.loadMailServers();
            }
        });
    };

    deleteMailServer = (serverId) => {
        if (confirm(this.gettextCatalog.getString('Удалить подключение к почте?'))) {
            this.MailServersService.delete(serverId).$promise.then(() => {
                this.loadMailServers();
            });
        }
    };

    detaildMailServer = (serverId, name) => {
        if (!this.mailServerUseAccess) return;
        this.$localStorage.mailServerDetailsData = {
            'windowName': name,
        };
        this.$injector.instantiate(MailServerDetailsModal).open(serverId);
    };

    loadMailServers = async (firstLoading) => {
        try {
            if (this.mailEnabled && this.mailViewAccess) {
                this.loading.mailServers = true;
                const fetchApi = FetchApi('/mail-server');
                const response = await fetchApi.get();
                if (response.isError) {
                    throw response.error;
                }
                this.mailServers = response;
                if (firstLoading && this.bossKadrovikEnabled && this.manageLdap) {
                    this.loadBossKadrovikProfiles(true);
                }
            } else {
                if (firstLoading && this.bossKadrovikEnabled && this.manageLdap) {
                    this.loadBossKadrovikProfiles(true);
                }
            }
            this.loading.mailServers = false;
            this.mailServersError = false;
        } catch (e) {
            this.loading.mailServers = false;
            this.mailServersError = true;
        }
    };

    getLoad = () => {
        this.reactLoad = true;
    }

    isValidEmail = (email) => {
        let isValid = false;
        if (this.domains && this.domains.length) {
            let regexp = new RegExp('(\\w+@[a-zA-Z_]+?\\.[a-zA-Z]{2,6})', 'gim');
            isValid = email && email.match(regexp);
            if (isValid) {
                isValid = false;
                angular.forEach(this.domains, (domain) => {
                    regexp = new RegExp('(\\w+@' + domain.replace('.', '\\.') + ')', 'gim');
                    if (email.match(domain)) {
                        isValid = true;
                    }
                });
            }
        }
        return isValid;
    };

    isValidEmails = (emails) => {
        let isValid = true;
        if (emails && emails.length) {
            angular.forEach(emails, (email) => {
                if (!this.isValidEmail(email)) {
                    isValid = false;
                }
            });
        }
        return isValid;
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

SettingsController.$inject = [
    '$injector',
    '$scope',
    '$localStorage',
    MAIL_SERVERS_SERVICE,
    SETTINGS_SERVICE,
    '$ngRedux',
    '$location',
    BOSS_KADROVIK_SERVICE,
    'gettextCatalog'
];

export {
    SettingsController,
};
