import { createSelector } from 'reselect';

const authSelector = (state) => {
    return state.auth.auth;
};

const rights = (state, props) => {
    return { state: state.auth.auth, props: props };
};

const getAuth = createSelector(authSelector, item => {
    return item;
});

export const hasAccess = createSelector(rights, item => {
    if (!item.state) {
        return false;
    }
    if (item.props.rightName === 'report_anonym_stat') {
        if (!item.state.anonymousStatisticsEnabled) {
            return false;
        }
    }
    if (item.state.adminId === 0) {
        return true;
    }
    if (item.props.sectionId === 8 && !item.state.license.features.manageCourse) {
        return false;
    }
    if (item.state.rights && item.state.rights[item.props.sectionId]) {
        if (item.props.rightName !== 'view' && item.state.rights[item.props.sectionId].indexOf('view') === -1) {
            return false;
        }
        return item.state.rights[item.props.sectionId].indexOf(item.props.rightName) > -1;
    }
    return false;
});

export const getCompanyName = createSelector([getAuth], authInfo => {
    return authInfo.companyName;
});

export const getAuthType = createSelector([getAuth], authInfo => {
    return authInfo.authType;
});

export const isSSO = createSelector([getAuth], authInfo => {
    return authInfo.authType === 3;
});

export const getUserName = createSelector([getAuth], authInfo => {
    return authInfo.username;
});

export const getUserTimeZone = createSelector([getAuth], authInfo => {
    return authInfo.timeZone;
});

export const getDomains = createSelector([getAuth], authInfo => {
    return authInfo.license.domain.split(', ');
});

export const isAuth = createSelector([getAuth], authInfo => {
    return authInfo && !!authInfo.email;
});
