import React, { useEffect, useContext } from 'react';
import { GettextContext, InjectorContext } from 'rootReact/context';
import { useDispatch, useSelector } from 'react-redux';
import {
    defaultNotification,
    customNotification,
    targetNotification,
    hasAccess
} from 'root/assets/webpack/src/js/reselect';
import DefaultList from './DefaultList.jsx';
import CustomList from './CustomList.jsx';
import { deleteNotification as deleteOneNotify, toDefaultOneNotify } from 'root/assets/webpack/src/js/toolkit/think';
import { ReactModal } from 'root/assets/webpack/src/js/components/ReactModal';
import FetchApi from '../../REST';
import NotificationModal from 'rootReact/components/NotificationModal/NotificationModal.jsx';
import { setOneTimeMessage, updateOneNotification } from 'root/assets/webpack/src/js/toolkit/actions';

function NotificationWrapper({}) {
    const injector = useContext(InjectorContext);
    const gettext = useContext(GettextContext);
    const dispatch = useDispatch();
    const editAccess = useSelector(state => hasAccess(state, { sectionId: 7, rightName: 'notification_save' }));
    const playAccess = useSelector(state => hasAccess(state, { sectionId: 7, rightName: 'notification_status' }));
    const notificationsDefault = useSelector(defaultNotification);
    const notificationsCustom = useSelector(customNotification);
    const notListLoad = useSelector(state => state.notificationsList.loading);
    const notListError = useSelector(state => state.notificationsList.error);
    const notListErrorText = useSelector(state => state.notificationsList.errorText);
    const load = useSelector(state => state.settings.load);

    const getNotificationApi = (id, toggle = false) => {
        let defaultNotify = false;
        notificationsDefault.forEach(item => {
            if (item.uuid === id) {
                defaultNotify = true;
            }
        });
        if (defaultNotify) {
            return FetchApi( toggle ? '/notifications/change-mailing-state/' + id : '/notifications');
        }
        return FetchApi(toggle ? '/custom-notifications/change-mailing-state/' + id : '/custom-notifications');
    };

    const getNotification = (id) => {
        let value;
        notificationsDefault.forEach(item => {
            if (item.uuid === id) {
                value = item;
            }
        });
        notificationsCustom.forEach(item => {
            if (item.uuid === id) {
                value = item;
            }
        });
        return value;
    };

    const toggleNotification = async (id) => {
        if (!playAccess) return;
        try {
            const notify = { ...getNotification(id) };
            const api = getNotificationApi(id, true);
            notify.status = +!notify.status;
            let response = await api.post({ body: { mailingState: notify.status } });
            if (response && response.isError) {
                throw response.err.error;
            }
            await dispatch(fetchListNotification());
        } catch (e) {
            dispatch(setOneTimeMessage(
                {
                    text: e,
                    isReady: 'danger',
                    progress: 100
                }
            ));
        }
    };

    const editNotification = async (id) => {
        const modal = injector.instantiate(ReactModal);
        let notify = await modal.open({
            component: NotificationModal,
            props: { id }
        });
        await dispatch(fetchListNotification());
    };

    const editCustomNotification = async (uuid) => {
        const modal = injector.instantiate(ReactModal);
        let notify = await modal.open({
            component: NotificationModal,
            props: { uuid }
        });
        await dispatch(fetchListNotification());
    };

    const deleteNotification = async (id, uuid) => {
        let confirmDelete = confirm(gettext.getString('Удалить уведомление?'));
        if (confirmDelete) {
            await dispatch(deleteOneNotify({ id, uuid }));
            await dispatch(fetchListNotification());
        }
    };

    const copyNotification = async (id, uuid) => {
        const modal = injector.instantiate(ReactModal);
        let notify = await modal.open({
            component: NotificationModal,
            props: {
                uuid,
                id,
                isCopy: true
            }
        });
        await dispatch(fetchListNotification());
    };

    const toDefault = async (id, uuid) => {
        if (!editAccess) return;
        if (!confirm(gettext.getString('Сбросить настройки уведомления до исходных?'))) return;
        await dispatch(toDefaultOneNotify({ id, uuid }));
        await dispatch(fetchListNotification());
    };

    const getDefaultItemList = () => {
        return <DefaultList
            notificationsList={notificationsDefault}
            toDefault={toDefault}
            toggleNotification={toggleNotification}
            editNotification={editNotification}
            copyNotification={copyNotification}
            editAccess={editAccess}
            playAccess={playAccess}
        />;
    };

    const getCustomItemList = () => {
        return <CustomList
            notificationsList={notificationsCustom}
            deleteNotification={deleteNotification}
            toggleNotification={toggleNotification}
            toDefault={toDefault}
            editAccess={editAccess}
            playAccess={playAccess}
            editNotification={editCustomNotification}
            copyNotification={copyNotification}
        />;
    };

    const createCustomNotification = async () => {
        if (!editAccess) return;
        const modal = injector.instantiate(ReactModal);
        let notifi = await modal.open({
            component: NotificationModal,
        });
    };

    return (
        <>
            <div className={'list'} data-testid={'notifications_section'}>
                <div className={'list_header'}>{gettext.getString('Уведомления')}</div>
                <div className={'list-name'}>
                    {gettext.getString('По обучению')}
                </div>
                <div className={'list-title'}>
                    {gettext.getString('Название')}
                </div>
                {!load && <div className={'notification-default_list'}>{getDefaultItemList()}</div>}
                <div className={'list_title-wrapper'}>
                    <div className={'list-name'}>
                        {gettext.getString('Пользовательские')}
                    </div>
                    <div className={'list_add-link'}>
                        <a className={editAccess ? '' : 'nolink'} onClick={createCustomNotification}>{gettext.getString('Новое уведомление')}</a>
                    </div>
                </div>
                <div className={'list_header-wrapper'}>
                    <div className={'list-title list_notification__first'}>
                        {gettext.getString('Название')}
                    </div>
                    <div className={'list-title list_notification__second'}>
                        {gettext.getString('Курсы')}
                    </div>
                    <div className={'list-title list_notification__third'}>
                        {gettext.getString('Действие')}
                    </div>
                    <div className={'list-title list_notification__fourth'}></div>
                </div>
                {!load && getCustomItemList()}
            </div>
        </>
    );
}

export default NotificationWrapper;
