import { createSelector } from 'reselect';

const LOAD_SUCCESS = 'fulfilled';

const settingsLoad = (state) => {
    return state.settings.load;
};

const certificateLoad = (state) => {
    return state.certificate.status;
};

const coursesLoad = (state) => {
    return state.courses.loading;
};

const wifiLoad = (state) => {
    return state.wifiDevice.loading;
};

const notificationLoad = (state) => {
    return state.notificationsList.loading;
};

const outlookSettingsLoad = (state) => {
    return state.outlook.firstLoading;
};

export const isSettingsLoad = createSelector(
    [settingsLoad, certificateLoad, notificationLoad, wifiLoad, coursesLoad, outlookSettingsLoad],
    (settingsLoad, certificateLoad, notificationLoad, wifiLoad, coursesLoad, outlookSettingsLoad) => {
        return !settingsLoad &&
            certificateLoad === LOAD_SUCCESS &&
            notificationLoad &&
            !wifiLoad &&
            !coursesLoad &&
            outlookSettingsLoad;
    }
);
