class MailServersService {
    constructor (YiiModel, $resource) {
        this.new = () => {
            return YiiModel.one('mail-server');
        };
        this.one = (id) => {
            return YiiModel.one('mail-server').$find(id);
        };
        this.all = YiiModel.all('mail-server');
        this.delete = (id) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/mail-server/' + id).delete();
        };
        this.getAll = () => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/mail-server').query();
        };
        this.details = (id, offset, goDown = 0, success, error) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/mail-server/' + id + '/details').query({
                'offset': offset,
                'goDown': goDown
            }, function (response) {
                if (typeof (success) == 'function') {
                    success(response);
                }
            }, function (response) {
                if (typeof (error) == 'function') {
                    error(response);
                }
            });
        };
        this.detailsOne = (id, mail_id, success, error) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/mail-server/' + id + '/details').query({
                'mail_id': mail_id
            }, function (response) {
                if (typeof (success) == 'function') {
                    success(response);
                }
            }, function (response) {
                if (typeof (error) == 'function') {
                    error(response);
                }
            });
        };
        this.markAsSeen = (message_id, use = false) => {
            return $resource(window.config.SYSTEM_URL + window.config.API_URL + '/mail-server/mark').save({}, {
                'message_id': message_id,
                'use': use
            });
        };
    }
}

export {
    MailServersService
};
