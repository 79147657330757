import { createSelector } from 'reselect';
import {
    actionTypes,
    reportTypes,
    triggerTypes
} from './schedulerTypes';

const templates = (state) => {
    return state.templates.templates;
};

const courses = (state) => {
    return state.courses.courses;
};

const types = (state) => {
    return {
        actionTypes: actionTypes(state),
        reportTypes: reportTypes(state),
        triggerTypes: triggerTypes(state),
    };
};

const extensibleData = (item) => {
    let newObj = {};
    for (let key in item) {
        newObj[key] = item[key];
    }
    return newObj;
};

export const notExtensibleTemplate = createSelector([templates], templates => {
    let returnedValue = templates.map(extensibleData);
    returnedValue.unshift({
        id: 0,
        name: 'Случайный шаблон'
    });
    return returnedValue;
});

export const notExtensibleCourses = createSelector([courses], courses => {
    return courses.map(extensibleData);
});

export const notExtensibleTrigger = createSelector([types], types => {
    return types.triggerTypes.map(extensibleData);
});

export const notExtensibleReport = createSelector([types], types => {
    return types.reportTypes.map(extensibleData);
});

export const notExtensibleAction = createSelector([types], types => {
    return types.actionTypes.map(extensibleData);
});
