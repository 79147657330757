import { createSelector } from 'reselect';
import { hasAccess } from './auth';

const NOTIFICATION_SECTOR = 7;

const actionSelector = (state) => {
    return state.settings.settings.actionTypes;
};

const triggerSelector = (state) => {
    return state.settings.settings.triggerTypes;
};

const reportSelector = (state) => {
    return state.settings.settings.reportTypes;
};

const accessNotification = (state) => {
    return hasAccess(state, { sectionId: NOTIFICATION_SECTOR, rightName: 'view' });
};

export const triggerTypes = createSelector([triggerSelector], (triggerTypes) => {
    return triggerTypes || [];
});

export const reportTypes = createSelector([reportSelector], (reportTypes) => {
    return reportTypes || [];
});

export const actionTypes = createSelector([actionSelector, accessNotification], (actionSelector, accessNotification) => {
    if (!accessNotification) {
        return actionSelector?.filter(item => item.id !== 'target_notify') || [];
    }
    return actionSelector || [];
});

