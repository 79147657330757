import { useState, useEffect, useContext, forwardRef } from 'react';
import style from './UserModal.module.less';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import { GettextContext } from 'rootReact/context';
import { useDispatch, useSelector } from 'react-redux';
import { useInput } from '../../hook/useValidModal.jsx';
import FetchApi from '../../REST';
import FaIcon from 'rootReact/components/FaIcon';
import Select, { components } from 'react-select';
import { fetchRoles, fetchSSO, fetchLdapProfiles } from '../../../toolkit/think';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { ru, en, it } from 'date-fns/locale';
import loader from '../../../../images/loading.svg';
import { addAccount, updateAccount } from '../../../toolkit/actions';
import { Cookies } from 'rootReact/helpers/Cookies';

function UserModal({ onModalClose, userId }) {
    const gettext = useContext(GettextContext);
    const dispatch = useDispatch();
    const [passwordUpdate, setPasswordUpdate] = useState(true);
    const [checkboxCalendar, setCheckboxCalendar] = useState(true);
    const [formError, setFormError] = useState('');
    const [formValid, setFormValid] = useState(false);
    const [save, setSave] = useState(false);
    const [loading, setLoading] = useState(false);
    const email = useInput('', { isEmpty: true, emailError: false });
    const password = useInput('', { isEmpty: true });
    const phone = useInput('', { isEmpty: true });
    const familyName = useInput('', { isEmpty: true });
    const firstName = useInput('', { isEmpty: true });
    const thirdName = useInput('', { isEmpty: true });
    const ldap = useInput('', { isEmpty: true });
    const ssoProfiles = useSelector((state) => state.ssoProfile.ssoProfiles || []);
    const ldapProfiles = useSelector((state) => state.ldapProfiles.ldapProfiles || []);
    const roleUsers = useSelector((state) => state.roleUsers.roles || []);
    const [ssoSelect, setSsoSelect] = useState({});
    const [ldapSelect, setLdapSelect] = useState({});
    const [isSuperAdmin, setSuperAdmin] = useState(false);
    const AUTH_TYPE_PASSWORD=1;
    const AUTH_TYPE_LDAP=2;
    const AUTH_TYPE_SSO=3;
    const [roleUsersSelect, setRoleUsersSelect] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [authType, setAuthType] = useState(AUTH_TYPE_PASSWORD);
    const [cleanLdap, setCleanLdap] = useState(false);
    const [cleanSso, setCleanSso] = useState(false);
    const langCode = new Cookies().getCookie('langCode');

    useEffect(() => {
        dispatch(fetchRoles());
        dispatch(fetchSSO());
        dispatch(fetchLdapProfiles());
    }, []);

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className='react-user-modal_calendar' onClick={onClick} ref={ref}>
            {format(new Date(value), 'dd MMMM hh:mm', { locale: langCode === 'ru' ? ru : langCode === 'it' ? it : en })}
        </button>
    ));

    const optionsSso = ssoProfiles.map((item) => {
        const newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        return newItem;
    });

    const optionsLdap = ldapProfiles.map((item) => {
        const newItem = {};
        newItem.value = item.id;
        newItem.label = item.title;
        return newItem;
    });

    const optionsRoles = roleUsers.map((item) => {
        const newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        return newItem;
    });

    const getValueSelect = (state, options) => {
        state ? options.find((item) => item.value === state) : '';
    };

    const getValueMultiRole = () => {
        roleUsersSelect ? optionsRoles.filter((item) => roleUsersSelect.indexOf(item.value) >= 0) : [];
    };

    const onChangeSelectSso = (newValue) => {
        setSsoSelect({ ...ssoSelect, id: newValue.value, name: newValue.label });
        setCleanSso(false);
    };

    const onChangeSelectLdap = (newValue) => {
        setLdapSelect({ ...ldapSelect, id: newValue.value, name: newValue.label });
        setCleanLdap(false);
    };

    const onChangeSelectRoles = (newValue) => {
        if (isSuperAdmin) return;
        setRoleUsersSelect(
            newValue.map((v) => {
                const newItem = {};
                newItem.id = v.value;
                newItem.name = v.label;
                return newItem;
            })
        );
    };

    const defaultValueRole = roleUsersSelect.map((item) => {
        const newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        return newItem;
    });

    const defaultValueLdap = ldapSelect !== null ? optionsLdap.find((item) => item.value === ldapSelect.id) : null;
    const defaultValueSso = ssoSelect !== null ? optionsSso.find((item) => item.value === ssoSelect.id) : null;

    const onClickPassword = () => {
        setAuthType((prev) => prev + 1); //показывается ldap
    };

    const onClickLdap = () => {
        setAuthType((prev) => prev + 1); //показывается sso
    };

    const onClickSso = () => {
        setAuthType((prev) => prev - 2); //показывается password
    };

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props} data-testid='dropdown-indicator'>
                <svg width='10' height='5' viewBox='0 0 10 5'>
                    <path d='M0 0L5 5L10 0H0Z' fill='#333333' />
                </svg>
            </components.DropdownIndicator>
        );
    };

    const onClickCleanLdap = () => {
        if (ldap.value.length) {
            ldap.onChange('');
        }
        if (ldapSelect !== null) {
            setCleanLdap(true);
            setLdapSelect(null);
        }
    };

    const onClickCleanSso = () => {
        if (ssoSelect !== null) {
            setCleanSso(true);
            setSsoSelect(null);
        }
    };

    const fetchApi = FetchApi('/users');
    const body = {
        authType: authType,
        familyName: familyName.value,
        firstName: firstName.value,
        thirdName: thirdName.value,
        isUserBlocked: !checkboxCalendar
            ? checkboxCalendar
            : checkboxCalendar && showCalendar
                ? new Date(startDate) / 1000
                : checkboxCalendar,
        login: email.value,
        page: 'targets',
        password: !password.value ? null : password.value,
        passwordNeedUpdate: passwordUpdate,
        phone: phone.value,
        ldapLogin: authType === AUTH_TYPE_LDAP ? ldap.value : '',
        ldapProfile: authType === AUTH_TYPE_LDAP ? ldapSelect : '',
        roles: roleUsersSelect,
        ssoProfile: authType === AUTH_TYPE_SSO ? ssoSelect && ssoSelect.id : null,
    };

    const onSaveUser = async () => {
        if (
            !email.value.length ||
            !familyName.value.length ||
            !firstName.value.length ||
            (authType === AUTH_TYPE_PASSWORD && !password.value.length && !userId) ||
            !phone.value.length ||
            (authType === AUTH_TYPE_LDAP && !ldap.value.length) ||
            (authType === AUTH_TYPE_LDAP && !ldapSelect.id) ||
            (authType === AUTH_TYPE_SSO && !ssoSelect.id)
        ) {
            setFormValid(true);
            return;
        }
        try {
            setFormError('');
            setSave(true);
            if (userId) {
                const response = await fetchApi.put(userId, {
                    body,
                });
                if (response && response.isError) {
                    throw response;
                }
                dispatch(updateAccount(response));
                onModalClose(response);
            } else {
                const response = await fetchApi.post({
                    body,
                });
                if (response && response.isError) {
                    throw response;
                }
                dispatch(addAccount(response));
                onModalClose(response);
            }
        } catch (e) {
            setSave(false);
            if (Array.isArray(e.err)) {
                const error = e.err.map((item) => {
                    const arr = [];
                    arr.push(item.message);
                    return arr;
                });
                setFormError(error.join(' '));
            } else {
                setFormError(gettext.getString('Что-то пошло не так :-('));
            }
        }
    };

    const onPasswordGenerate = async () => {
        try {
            const fetchApi = FetchApi('/settings/password-generate');
            let response = await fetchApi.post();
            if (response) {
                password.onChange(Object.values(response.password).join(''));
            }
        } catch (e) {
            setFormError(e.error || gettext.getString('Что-то пошло не так :-('));
        }
    };

    useEffect(() => {
        setPasswordUpdate(authType === AUTH_TYPE_PASSWORD);
    }, [authType]);

    useEffect(() => {
        async function fetchUserData() {
            const res = await fetchApi.get(userId);
            setAuthType(res.authType);
            familyName.onChange(res.familyName);
            firstName.onChange(res.firstName);
            thirdName.onChange(res.thirdName);
            email.onChange(res.login);
            phone.onChange(res.phone);
            setRoleUsersSelect(res.roles);
            setSsoSelect(res.ssoProfile);
            setLdapSelect(res.ldapProfile);
            ldap.onChange(res.ldapLogin);
            setShowCalendar(res.isUserBlocked !== false && res.isUserBlocked !== true ? true : false);
            setCheckboxCalendar(res.isUserBlocked !== false ? true : false);
            setStartDate(
                res.isUserBlocked !== false && res.isUserBlocked !== true
                    ? new Date(res.isUserBlocked * 1000)
                    : new Date()
            );
            setSuperAdmin(res.superAdmin);
            setPasswordUpdate(res.passwordNeedUpdate);
            setLoading(false);
        }
        try {
            if (userId) {
                setLoading(true);
                fetchUserData();
            }
        } catch (error) {
            setLoading(false);
            setFormError('Что-то пошло не так :-(');
        }
    }, [userId]);

    const getField = ({name, fieldName, params, placeholder, type, require = true}) => {
        return <div className='react-user-modal_block'>
            <label
                    className={
                        (formValid && require && !params.value.length) || (require && params.isDirty && params.isEmpty)
                                ? 'react-user-modal_label error-label'
                                : 'react-user-modal_label'
                    }
                    htmlFor={name}
                    data-testid={`${name}_label`}
            >
                {fieldName}
            </label>
            <input
                    onChange={(e) => params.onChange(e.target.value)}
                    onBlur={(e) => params.onBlur(e)}
                    value={params.value}
                    disabled={isSuperAdmin}
                    className={`${(formValid && require && !params.value.length) || (require && params.isDirty && params.isEmpty)
                            ? 'react-user-modal_input error-input'
                            : 'react-user-modal_input'} ${isSuperAdmin && 'react-input_field__untouched'}`

                    }
                    type={type}
                    name={name}
                    id={name}
                    data-testid={'full_name_field'}
                    placeholder={placeholder}
            />
        </div>
    }

    return (
        <>
            <ModalHeader
                title={
                    userId
                        ? gettext.getString('Редактирование учетной записи')
                        : gettext.getString('Новая учетная запись')
                }
                closeModal={() => onModalClose()}
            />
            {loading ? (
                <div className={'react-user-modal_loader'}>
                    <img src={loader} />
                </div>
            ) : (
                <div className='react-user-modal'>
                    <div className='react-user-modal_block'>
                        <label
                            className={
                                (formValid && !email.value.length) ||
                                            (email.isDirty && email.isEmpty) ||
                                            (email.isDirty && email.emailError)
                                    ? 'react-user-modal_label error-label'
                                    : 'react-user-modal_label'
                            }
                            htmlFor='email'
                            data-testid={'email_label'}
                        >
                            {gettext.getString('Электронная почта')}
                        </label>
                        <input
                            onChange={(e) =>
                                email.onChange(e.target.value.replace(/[а-яёА-ЯЁ]/g, ''))
                            }
                            onBlur={(e) => email.onBlur(e)}
                            value={email.value}
                            disabled={isSuperAdmin}
                            className={`${(formValid && !email.value.length) ||
                                        (email.isDirty && (email.isEmpty || email.emailError))
                                ? 'react-user-modal_input error-input'
                                : 'react-user-modal_input'} ${isSuperAdmin && 'react-input_field__untouched'}`

                            }
                            type='email'
                            name='email'
                            id='email'
                            data-testid={'email_field'}
                            required
                        />
                    </div>
                    {authType === AUTH_TYPE_PASSWORD && <div className={'react-user-modal_checkblock'}>
                        <div className='react-user-modal_password-block'>
                            <label
                                onClick={onClickPassword}
                                className={'react-user-modal_label-link'}
                                data-testid='auth-type-switcher-password'
                                htmlFor='password'
                            >
                                {gettext.getString('Пароль')}
                            </label>
                            <div className='react-user-modal_password'>
                                <input
                                    onChange={(e) => password.onChange(e.target.value)}
                                    onBlur={(e) => password.onBlur(e)}
                                    value={password.value}
                                    className={
                                        (formValid && !password.value.length && authType === AUTH_TYPE_PASSWORD) ||
                                                    (password.isDirty && password.isEmpty)
                                            ? 'react-user-modal_input error-input'
                                            : 'react-user-modal_input input-pass'
                                    }
                                    type='text'
                                    name='password'
                                    id='password'
                                    data-testid={'password_field'}
                                    required
                                />
                                <button onClick={onPasswordGenerate}
                                    className='react-user-modal_btn'
                                    data-testid={'password_generate_button'}
                                >
                                    {gettext.getString('Сгенерировать')}
                                </button>
                            </div>
                        </div>
                        <div className='react-user-modal_checkbox'>
                            <input
                                onChange={() => setPasswordUpdate((prev) => !prev)}
                                checked={passwordUpdate}
                                className='checkox-input'
                                type='checkbox'
                                data-testid={'password_update_checkbox'}
                                id='checkbox'
                            />
                            <label htmlFor='checkbox'
                                className='checkbox-label'
                                data-testid={'checkbox_label'}
                            >
                                {gettext.getString('Обновить пароль при авторизации')}
                            </label>
                        </div>
                    </div>}
                    {authType === AUTH_TYPE_LDAP && <div className={'react-user-modal_block'}>
                        <label onClick={onClickLdap}
                            className={'react-user-modal_label-link'}
                            data-testid='auth-type-switcher-ldap'
                            htmlFor='ldap'
                        >
                            {gettext.getString('LDAP')}
                        </label>
                        <div className='react-user-modal_ldap'>
                            <div className='react-user-modal_select'>
                                <Select
                                    classNamePrefix='user-modal_select'
                                    onChange={onChangeSelectLdap}
                                    value={cleanLdap ? null : getValueSelect(ldapSelect, optionsLdap)}
                                    options={optionsLdap}
                                    isSearchable={false}
                                    placeholder={gettext.getString('Выберите профиль')}
                                    data-testid='auth-select-ldap'
                                    components={{ DropdownIndicator }}
                                    defaultValue={userId && authType === AUTH_TYPE_LDAP ? defaultValueLdap : ''}
                                />
                            </div>
                            <span>\</span>
                            <input
                                onChange={(e) => ldap.onChange(e.target.value)}
                                onBlur={(e) => ldap.onBlur(e)}
                                value={ldap.value}
                                className={
                                    (authType === AUTH_TYPE_LDAP && formValid && !ldap.value) ||
                                                (ldap.isDirty && ldap.isEmpty)
                                        ? 'react-user-modal_input error-input'
                                        : 'react-user-modal_input'
                                }
                                type='text'
                                name='ldap'
                                id='ldap'
                                placeholder={gettext.getString('Введите логин')}
                            />
                            {ldapSelect !== null || ldap.value ? (
                                <button onClick={onClickCleanLdap} className='react-user-modal_clean-ldap'>
                                                ×
                                </button>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>}
                    {authType === AUTH_TYPE_SSO && <div className={'react-user-modal_block react-user-modal_clean-block'}>
                        <label onClick={onClickSso}
                            data-testid='auth-type-switcher-sso'
                            className={'react-user-modal_label-link-sso'}
                            htmlFor='sso'
                        >
                            {gettext.getString('SSO')}
                        </label>
                        <div className='react-user-modal_select-sso' data-testid='auth-select-sso'>
                            <Select
                                classNamePrefix='user-modal_select'
                                onChange={onChangeSelectSso}
                                value={cleanSso ? null : getValueSelect(ssoSelect, optionsSso)}
                                options={optionsSso}
                                isSearchable={false}
                                placeholder={gettext.getString('Выберите профиль')}
                                data-testid='auth-select-sso'
                                components={{ DropdownIndicator }}
                                defaultValue={userId && authType === 3 ? defaultValueSso : ''}
                            />
                        </div>
                        {ssoSelect !== null && (
                            <button onClick={onClickCleanSso} className='react-user-modal_clean-sso'>
                                            ×
                            </button>
                        )}
                    </div>}
                    {getField({
                        name: 'familyName',
                        fieldName: gettext.getString('Фамилия'),
                        params: familyName,
                        placeholder: gettext.getString('Фамилия'),
                        type: 'text'}
                    )}
                    {getField({
                        name: 'firstName',
                        fieldName: gettext.getString('Имя'),
                        params: firstName,
                        placeholder: gettext.getString('Имя'),
                        type: 'text'}
                    )}
                    {getField({
                        name: 'thirdName',
                        fieldName: gettext.getString('Отчество'),
                        params: thirdName,
                        placeholder: gettext.getString('Отчество'),
                        type: 'text',
                        require: false
                    }
                    )}
                    <div className='react-user-modal_block'>
                        <label
                            className={
                                (formValid && !phone.value.length) || (phone.isDirty && phone.isEmpty)
                                    ? 'react-user-modal_label error-label'
                                    : 'react-user-modal_label'
                            }
                            htmlFor='tel'
                            data-testid={'tel_label'}
                        >
                            {gettext.getString('Телефон')}
                        </label>
                        <input
                            onChange={(e) => phone.onChange(e.target.value)}
                            onBlur={(e) => phone.onBlur(e)}
                            value={phone.value}
                            disabled={isSuperAdmin}
                            className={`${(formValid && !phone.value.length) || (phone.isDirty && phone.isEmpty)
                                ? 'react-user-modal_input error-input'
                                : 'react-user-modal_input'} ${isSuperAdmin && 'react-input_field__untouched'}`
                            }
                            type='tel'
                            name='tel'
                            id='tel'
                            data-testid={'phone_number_field'}
                        />
                    </div>
                    <div className='react-user-modal_block' data-testid={'role_field'}>
                        <label className='react-user-modal_label'
                            htmlFor='role'
                            data-testid={'role_field_label'}
                        >
                            {gettext.getString('Роли')}
                        </label>
                        <div className={`react-user-modal_select ${style.select}`}>
                            <Select classNamePrefix='user-modal_select-role'
                                onChange={onChangeSelectRoles}
                                value={getValueMultiRole()}
                                options={optionsRoles}
                                isDisabled={isSuperAdmin}
                                isSearchable
                                data-testid='auth-select-role'
                                placeholder={gettext.getString('Выберите роли')}
                                isMulti
                                defaultValue={userId ? defaultValueRole : ''}
                            />
                        </div>
                    </div>
                    <div className='react-user-modal_checkbox'>
                        <input
                            onChange={() => setCheckboxCalendar((prev) => !prev)}
                            checked={checkboxCalendar}
                            disabled={isSuperAdmin}
                            className='checkox-input'
                            type='checkbox'
                            data-testid={'access_blocked_checkbox'}
                        />
                        <label className='checkbox-label'
                            data-testid={'access_blocked_checkbox_label'}
                        >
                            <span>{gettext.getString('Вход заблокирован')}&nbsp;</span>
                            {showCalendar ? (
                                <>
                                    <span onClick={() => !isSuperAdmin && setShowCalendar(false)} className='react-user-modal_link'>
                                        {gettext.getString('до')}
                                    </span>
                                    <div className={`react-user-modal_calendar ${style.calendar}`}>
                                        <DatePicker
                                            popperPlacement='top'
                                            selected={startDate}
                                            onChange={(date) => !isSuperAdmin && setStartDate(date)}
                                            customInput={<ExampleCustomInput />}
                                            minDate={new Date()}
                                            timeInputLabel='Time:'
                                            showTimeInput
                                            data-testid='auth-modal-calendar'
                                        />
                                    </div>
                                </>
                            ) : (
                                <span onClick={() => !isSuperAdmin && setShowCalendar(true)} className='react-user-modal_link' href='#'>
                                    {gettext.getString('навсегда')}
                                </span>
                            )}
                        </label>
                    </div>
                </div>
            )}

            <div className='react-modal-form_error'>
                <ModalFooter
                    titleBtnOk={userId ? gettext.getString('Сохранить') : gettext.getString('Добавить')}
                    titleBtnClose={gettext.getString('Отменить')}
                    closeModal={() => onModalClose()}
                    onEnter={onSaveUser}
                    disabledCancel={loading || save ? true : false}
                    disabled={
                        loading ||
                                save ||
                                (email.isDirty && !email.inputValid) ||
                                (familyName.isDirty && familyName.isEmpty) ||
                                (firstName.isDirty && firstName.isEmpty) ||
                                (password.isDirty && password.isEmpty) ||
                                (phone.isDirty && phone.isEmpty) ||
                                (authType === AUTH_TYPE_PASSWORD && password.isDirty && password.isEmpty)
                            ? true
                            : false
                    }
                />
                {formError.length !== 0 ? <FaIcon className='react-icon-error' type={'error'} text={formError} /> : ''}
                {save ? <FaIcon type={'pending'} text={gettext.getString('Сохранение...')} /> : ''}
            </div>
        </>
    );
}

export default UserModal;
